import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className='button_container'>
        <h1>Rapports techniciens Hanvolt</h1>
        <a
          href='https://forms.monday.com/forms/489779bfce9d8ae2d8b67d22ad3cb90b?r=euc1'
          className='button'
        >
          Rapport de visite technique
        </a>
        <a
          href='https://forms.monday.com/forms/aa26b384119ab3d44e817e2eb944ebd8?r=euc1'
          className='button'
        >
          Fin de chantier
        </a>
      </div>
    </div>
  );
}

export default App;
